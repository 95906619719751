import bm from './config';
import { createPublicKey } from 'crypto';

const eventListeners = () => {

    const tableTriggers = document.getElementsByClassName('chapterToggle');
    if (tableTriggers) {
        addListenersToToggles();
    }

    function addListenersToToggles() {
        for (let i = 0; i < tableTriggers.length; i++) {
            bm.events.subscribe('click', tableTriggers[i], (e) => {// jshint ignore:line
                //e.preventDefault();     
                var item = tableTriggers[i];
                const triggerClassList = item.classList;
                const toggleId = item.getAttribute('data-toggle');
                const subTables = document.getElementsByClassName('sub__chapters');
                for (var t = 0; t < subTables.length; t++) {
                    var table = subTables[t];
                    if (table.getAttribute('data-toggle') === toggleId) {
                        table.classList.toggle('active');
                        item.classList.toggle('active');
                    }
                }
            });
        }
    }


    var searchNumber = 0;
    //ON CLICK ADD SEARCH DROP DOWN
    const searchDocument = document.getElementById('searchDocument');
    const searchDrop = document.getElementById('searchDrop');
    const searchTerm = document.getElementById('searchTerm');
    const searchError = document.getElementById('searchError');
    if (searchDocument) {
        searchNumber = 0;
        searchDocument.onclick = function(e) {
            e.preventDefault();
            if (searchTerm && searchTerm.value) {
                search(searchTerm.value, searchNumber);
                searchDrop.classList.add("active");    
            } else {
                searchError.classList.add("active");
                setTimeout(function(){
                    searchError.classList.remove("active");
                }, 3000);
            }
        };
        searchTerm.addEventListener("keyup", function(event) {
            if (event.keyCode === 13) {
            event.preventDefault();
            document.getElementById("searchDocument").click();
            }
        });
    }
    var sTerm;
    //GET SEARCH RESULTS
    var totalResults = 0;
    function search(term, num) {
        sTerm = term;
        $.ajax({
            type: "GET",
            data: { 'searchString': term , 'resultNum': num, 'parNum': par},
            url: "/Umbraco/surface/Search/Search/v1"
        }).done(function (res) {
            $('#searchDrop').html(res);
            //ONCLICK OF PREVIOUS SEARCH ARROW
            if (document.getElementById('searchNext')) { document.getElementById('searchNext').addEventListener('click', clickNext); }
            if (document.getElementById('totalNumber')) { totalResults = parseInt(document.getElementById('totalNumber').innerText); }
            //ONCLICK OF NEXT SEARCH ARROW
            if (document.getElementById('searchPrevious')) { document.getElementById('searchPrevious').addEventListener('click', clickPrevious); } 
            //CLICK CLOSE
            if (document.getElementById('closeSearch')) { document.getElementById('closeSearch').addEventListener('click', closeSearch); } 
            //put the results in the main section
            $.ajax({
                type: "GET",
                data: { 'searchString': sTerm, 'resultNum': num, 'parNum': par },
                url: "/Umbraco/surface/Search/SearchResults/v1"
            }).done(function (results) {
                $('#documentContainer').html(results);
                if (num === 0) {
                    $(window).scrollTop(0);
                }
                else {
                    // the next line is required to work around a bug in WebKit (Chrome / Safari)
                    var elToShow = document.getElementById(num);
                    elToShow.scrollIntoView({ block: 'end' });
                }
                doSearch();
            });
        });
    }
    function doSearch() {
        var vl = sTerm;
        $("#searchresults").removeHighlight();
        $("#searchresults").highlight(vl);
    }

    //function doSearch() {
    //    var highlightHtml = '<span class="searchLight">$1</span>';
    //    if ($('#searchTerm').val().length !== 0) {
    //        var term = sTerm;
    //        var txt = $('.searchresults').html();
    //        console.log("txt", txt);
    //        txt = txt.replace(new RegExp('(' + term + ')', 'gi'), highlightHtml);
    //        console.log("new text", txt);
    //        $('.searchresults').html(txt);
    //    }
    //}

    

    //CLOSE SEARCH DROPDOWN
    function closeSearch() {
        searchNumber = 0;
          searchDrop.classList.remove("active");
    }

    function clickPrevious() {
        if (searchNumber !== 0) {
            searchNumber = searchNumber - 1;
        }
        else {
            searchNumber = totalResults - 1;
        }
        search(searchTerm.value, searchNumber);
    }

    function clickNext() {
        if (searchNumber !== totalResults -1) {
            searchNumber = searchNumber + 1;
        }
        else {
            searchNumber = 0;
        }
        search(searchTerm.value, searchNumber);
    }

    //PRINT DROPDOWN TRIGGER
    const trigger = document.getElementById("printCustom");
    if (trigger) {
        trigger.addEventListener("click", showPrintDropDown);
    }

    function showPrintDropDown() {
        var box = document.getElementById("printCustomBox");
        box.style.right = "auto";
        box.style.top = "auto";
        if (box.style.display !== "none") {
            box.style.display = "none";
        }
        else {
            box.style.display = "block";
        }
    }

 

    //NAVIGATION TRIGGERS
    const triggers = document.getElementsByClassName('navigation__drop__trigger');
    if(triggers) {
        for (let i = 0, item; !!(item = triggers[i++]);) {
            // need to use jshint ignore:line as it doesn't recognise the new scoped declaration of let instead of var
            bm.events.subscribe('click', item, (e) => {// jshint ignore:line
                e.preventDefault();
                const triggerClassList = item.parentNode.classList;
                const add = triggerClassList.contains('active') ? false : true;
                //Close any others
                for (let t = 0, trigger; !!(trigger = triggers[t++]);) {
                    const existingTriggerClassList = trigger.parentNode.classList;
                    if (existingTriggerClassList.contains('active')) {
                        existingTriggerClassList.remove('active');
                    }    
                }
                if (add) {
                    triggerClassList.add('active');
                }
            });
        }
    }
    
    //ACCORDION
    const acc = document.getElementsByClassName("accordion");
    if(acc) {
        for (let i = 0; i < acc.length; i++) {
            acc[i].addEventListener("click", function() {
                this.classList.toggle("active");
                var panel = this.nextElementSibling;
                if (panel.style.maxHeight){
                    panel.style.maxHeight = null;
                } else {
                    panel.style.maxHeight = panel.scrollHeight + "px";
                } 
            });
        }
    }


    //HIDE/SHOW SUB SECTION
    const subContentHeader = document.querySelectorAll('.content__header:not(.main)');
    if (subContentHeader) {
        for (let i = 0; i < subContentHeader.length; i++) {
            subContentHeader[i].addEventListener("click", function(e) {
                e.preventDefault();
                this.classList.toggle("hide");
                var panel = this.nextElementSibling; 
                panel.classList.toggle("hide");
                var hiddenHeader = this.parentNode.getElementsByClassName("hidden__header")[0];
                if (hiddenHeader.style.display !== "none") {
                    hiddenHeader.style.display = "none";
                }
                else {
                    hiddenHeader.style.display = "block";
                }
                var panel2 = this.nextElementSibling.nextElementSibling;
                panel2.classList.toggle("hide");                
            });
        }
    }


    //HIDE/SHOW SECTION
    const contentHeader = document.getElementsByClassName("content__header main");
    if (contentHeader) {
        for (let i = 0; i < contentHeader.length; i++) {
            contentHeader[i].addEventListener("click", function (e) {
                e.preventDefault();
                this.classList.toggle("hide");
                var panel = this.nextElementSibling.nextElementSibling;
                panel.classList.toggle("hide");
                var hiddenHeader = this.parentNode.getElementsByClassName("hidden__header");
                var ourHeader = hiddenHeader[hiddenHeader.length - 1];
                if (ourHeader.style.display !== "none") {
                    ourHeader.style.display = "none";
                }
                else {
                    ourHeader.style.display = "block";
                }
            });
        }
    }

    //MENU TOGGLE OUTSIDE NAV
    if (navigator.userAgent.indexOf('Edge') <= 0){
        var toggle = document.getElementById('toggle');
        var nav = document.getElementById('nav');
        toggle.addEventListener('click', function(e) {
            nav.classList.toggle("active");
        },false);

        window.addEventListener('click', function(e){
            if (nav.classList.contains("active") && !document.getElementById('nav').contains(e.target) && !document.getElementById('hamburger').contains(e.target) ){
                var element = document.getElementById("hamburger");
                if(element.click)
                    element.click();
                else if(document.createEvent)
                {
                    var eventObj = document.createEvent('MouseEvents');
                    eventObj.initEvent('click',true,true);
                    element.dispatchEvent(eventObj);
                }
            } 
        });
    }

    getDocumentChanges();

    //CHECK FOR DOCUMENT CHANGES
    //TODO: ADD USER ID
    function getDocumentChanges() {
        $.ajax({
            type: "GET",
            url: "/Umbraco/Api/ChangeTrackerApi/GetDocumentChanges"
        }).done(function (res) {
            //if there is one, show the notification bar
            console.log("result", res);
            for (let i = 0; i < res.length; i++) {
                topBar("There has been a change : <a id='goViewChange' name='" + res[i].ChangeId + "' href='" + res[i].Url + "'>" + res[i].Title + "</a> <span name ='" + res[i].ChangeId + "' id='closeMessage'></span>");
                //CLOSE MESSAGE AND MARK IT AS SEEN        
                document.getElementById("closeMessage").onclick = markSeen;
                document.getElementById("goViewChange").onclick = markSeen;

            }        
        });
    }

    //MARK THE CHANGE AS SEEN
    function markSeen() {
        var changeId = this.getAttribute("name");
        console.log("changeId", changeId);
        $.ajax({
            url: "/Umbraco/Api/ChangeTrackerApi/MarkChangeAsSeen",
            data: { ChangeId: changeId},
            dataType: 'JSON',
            method: "POST",
            success: function (data) {
                console.log("done");
                $("div.topbar").remove();
                getDocumentChanges();
            }
        });
    }


    //NOTIFICATIONS FOR NEW DOCUMENTS
    function topBar(message) {
        $("<div />", { class: 'topbar', html: message }).hide().prependTo("main")
            .slideDown('fast');//.delay(10000).slideUp(function () { $(this).remove(); });
    }
    addHiddenDivs();

    //ADD HIDDEN SECTION DIV TO ALL HIDEABLE DIVS
    function addHiddenDivs() {
        var listOfDivs = document.getElementsByClassName("content__wrapper");
        for (var i = 1; i < listOfDivs.length; i++) {
            var objTo = listOfDivs[i];
            var hiddenDiv = document.createElement("h4");
            hiddenDiv.classList.add("hidden__header");
            hiddenDiv.style.display = "none";
            hiddenDiv.style.color = "red";
            hiddenDiv.innerHTML = "This section is hidden";
            objTo.appendChild(hiddenDiv);
        }
    }
};


export default eventListeners;