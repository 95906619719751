const notes = () => {

    var noteTrigger = document.getElementById('noteTrigger');
    if (noteTrigger) {
        noteTrigger.addEventListener('click', notes);
    }

    var userNote;
    var notehighlight;
    function S4() {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    }
    var id = (S4() + S4() + "-" + S4() + "-4" + S4().substr(0, 3) + "-" + S4() + "-" + S4() + S4() + S4()).toLowerCase();

    var highlighter = rangy.createHighlighter();
    highlighter.addClassApplier(rangy.createClassApplier('notesSelect', {
        onElementCreate: function (el) {
            el.setAttribute('name', id);
        },
        elementProperties: {
            className: id
        }
    }));

    function notes(e) {
       
        e.preventDefault();
        highlighter.highlightSelection('notesSelect'); 
        var rangySelection = rangy.getSelection();
        var target = rangySelection.focusNode.parentNode;
        notehighlight = highlighter.getHighlightForElement(target);
        if (notehighlight) {
            addNotesModal(e, target, notehighlight, id);
            rangy.getSelection().removeAllRanges();
        }
        id = (S4() + S4() + "-" + S4() + "-4" + S4().substr(0, 3) + "-" + S4() + "-" + S4() + S4() + S4()).toLowerCase();
    }


    // document.onmouseover = function(e) {
    //     e = e || window.event;
    //     var target = e.target || e.srcElement;
    //     toggleNoteModal(e, target);
    // };

    document.onclick = function(e) {
        e = e || window.event;
        var target = e.target || e.srcElement;
        toggleNoteModal(e, target);
    };

    function toggleNoteModal(e, target) {
        console.log("clicked");
        if (target.classList.contains('notesSelect')) {
            var noteId = target.getAttribute('name');
            console.log("noteId", noteId);
            //instead of toggling by notesbox as below, toggle by id
            var notes = document.getElementById(noteId);
            console.log("notes", notes);
            if (notes) {
                notes.classList.toggle("hide");
                var notesEditModal = document.getElementsByClassName("notesModal");
                while (notesEditModal[0]) {
                    notesEditModal[0].parentNode.removeChild(notesEditModal[0]);
                }
            }
        }
        if (target.classList.contains('highlight')) {
            console.log("it's a highlight");
            //check it doesn't already have a modal
            var dd = target.querySelector('.highlightModal');
            console.log("dd", dd);
            if (dd === null) {
                console.log("in herrrrrrr");
                var modal = document.createElement('div');
                modal.className = "highlightModal";
                target.appendChild(modal);
                var targetId = target.getAttribute('name');
                var confirm = document.createElement('a');
                confirm.className = "btn btn--standard confirm jcq";
                confirm.innerHTML = "Clear Highlight";
                confirm.addEventListener('click', function (e) {
                    target.classList.remove('highlight');
                    modal.parentNode.removeChild(modal);
                    if (highlight) {
                        console.log("highlight");
                        highlighter.removeHighlights([highlight]);
                    }
                    console.log("target Id", targetId);
                    clearHighlights(targetId);
                });
                var cancel = document.createElement('a');
                cancel.className = "btn btn--standard cancel jcq";
                cancel.innerHTML = "Cancel";
                cancel.addEventListener('click', function (e) {
                    e.preventDefault();
                    modal.parentNode.removeChild(modal);
                });

                modal.appendChild(confirm);
                modal.appendChild(cancel);
            }
            else {
                target.removeChild(dd);
            }
        }
    }

    function clearHighlights(id) {
        var els = document.getElementsByClassName(id);
        console.log("els", els);
        console.log("els length", els.length);
        for (var i = 0; i < els.length; i++) {
            var inner = els[i].innerHTML;
            console.log("inner", inner);
            els[i].outerHTML = inner;
            els[i].classList.remove("highlight");
            els[i].removeAttribute("name");
        }
        if (document.getElementsByName(id).length > 0) {
            clearHighlights(id);
        }
    }

    function addNotesModal(e, target, notehighlight, curId) {
        var modal = document.createElement('div');
        modal.className = "notesModal";
        target.appendChild(modal);

        var ModalContent = `
            <span>Add Note</span>
            <textarea id="note" autofocus></textarea>
        `;
        const HTML = document.createRange().createContextualFragment(ModalContent, 'text/html');
        
        var addNote = document.createElement('a');
        addNote.className = "btn btn--standard confirm jcq";
        addNote.innerHTML = "Add Note";
        addNote.addEventListener('click', function(e) {
                notesBoxModal(e, target, modal, notehighlight, curId);
        });


        var cancel = document.createElement('a');
        cancel.className = "btn btn--standard cancel jcq";
        cancel.innerHTML = "Cancel";
        cancel.addEventListener('click', function(e) {
            e.preventDefault();
            modal.parentNode.removeChild(modal);
            highlighter.removeHighlights( [notehighlight] );
        });

        modal.appendChild(HTML);
        modal.appendChild(addNote);
        modal.appendChild(cancel);
    }

    function notesBoxModal(e, target, modal, notehighlight, curId) {
        var note = document.getElementById("note");
        userNote = note.value;
        modal.parentNode.removeChild(modal);

        var notesBox = document.createElement('div');
        notesBox.className = "notesBox";
        notesBox.id = curId;
        target.appendChild(notesBox);

        var noteContents = document.createElement('p');
        noteContents.innerHTML = userNote;
        notesBox.appendChild(noteContents);

        var close = document.createElement('span');
        close.className = "closeButton";
        close.addEventListener('click', function (e) {
            this.parentNode.classList.toggle('hide');
        });
        close.innerHTML = "X";

        var editNote = document.createElement('a');
        editNote.className = "btn btn--standard confirm jcq";
        editNote.innerHTML = "Edit Note";
        editNote.name = "editNote";
        editNote.addEventListener('click', function(e) {
            var noteEdited = noteContents.innerHTML;
            editNotesModal(e, target, noteContents, noteEdited);
        });

        var deleteNote = document.createElement('a');
        deleteNote.className = "btn btn--standard confirm jcq";
        deleteNote.innerHTML = "Delete Note";
        deleteNote.name = "deleteNote";
        deleteNote.addEventListener('click', function(e) {
            e.preventDefault();
            notesBox.parentNode.removeChild(notesBox);
            highlighter.removeHighlights( [notehighlight] );
        });

        notesBox.appendChild(close);
        notesBox.appendChild(editNote);
        notesBox.appendChild(deleteNote);
    }

    var editElements = document.getElementsByName("editNote");
    if (editElements) {
        for (var i = 0; i < editElements.length; i++) {
            editElements[i].addEventListener("click", editNotes);
        }
    }

    var closeButtons = document.getElementsByClassName("closeButton");
    if (closeButtons) {
        for (var j = 0; j < closeButtons.length; j++) {
            closeButtons[j].addEventListener("click", function (e) {
                this.parentNode.classList.toggle("hide");
            });
        }
    }

    function editNotes(e) {
        var notesBox = e.target.closest("div");
        var noteContents = notesBox.getElementsByTagName("p")[0];
        var noteEdited = noteContents.innerHTML;
        editNotesModal(e, e.target, noteContents, noteEdited);
    }

    var deleteElements = document.getElementsByName("deleteNote");
    if (deleteElements) {
        for (var k = 0; k < deleteElements.length; k++) {
            deleteElements[k].addEventListener("click", deleteNotes);
        }
    }

    function deleteNotes(e) {
        e.preventDefault();
        console.log("e", e);
        var notesBox = e.target.closest("div");
        var noteId = notesBox.id;
        notesBox.parentNode.removeChild(notesBox);
        highlighter.removeHighlights([notehighlight]);
        $('#' + noteId).remove();
        var highlights = document.getElementsByClassName(noteId);
        for (var i = 0; i < highlights.length; i++) {
            highlights[i].classList.remove("notesSelect");
            highlights[i].removeAttribute("name");
        }
    }


    function editNotesModal(e, target, noteContents, noteEdited) {
        var modal = document.createElement('div');
        modal.className = "notesModal";
        target.appendChild(modal);

        var ModalContent = `
            <span>Edit Note</span>
        `;
        const HTML = document.createRange().createContextualFragment(ModalContent, 'text/html');

        var editTextArea = document.createElement('textarea');
        editTextArea.setAttribute("id", "note");
        editTextArea.innerHTML = noteEdited;

        var editNote = document.createElement('a');
        editNote.className = "btn btn--standard confirm jcq";
        editNote.innerHTML = "Confirm Edit";
        editNote.addEventListener('click', function (e) {
            noteContents.innerHTML = editTextArea.value;
            modal.parentNode.removeChild(modal);
            userNote = editTextArea.value;
        });

        var cancelEdit = document.createElement('a');
        cancelEdit.className = "btn btn--standard cancel jcq";
        cancelEdit.innerHTML = "Cancel Edit";
        cancelEdit.addEventListener('click', function (e) {
            e.preventDefault();
            modal.parentNode.removeChild(modal);
        });

        modal.appendChild(HTML);
        modal.appendChild(editTextArea);
        modal.appendChild(editNote);
        modal.appendChild(cancelEdit);
    }

};

export default notes;