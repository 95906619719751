const highlight = () => {

    var highlightTrigger = document.getElementById('highlightTrigger');
    if (highlightTrigger) {
        highlightTrigger.addEventListener('click', highlight);
    }
    var model = {
        Id: "",
        SavedName: "",
        UserId: "",
        DocumentId: "",
        //  Highlights: [],
        //  UserHiddenSection: []
        UserSavedChapters: []
    };

    var chapMod = {
        ParentId: "",
        HtmlToShow: "",
        ChapterId: "",
        Id: ""
    };
  
    rangy.init();

    function S4() {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    }

    var id = (S4() + S4() + "-" + S4() + "-4" + S4().substr(0, 3) + "-" + S4() + "-" + S4() + S4() + S4()).toLowerCase();
    var highlighter = rangy.createHighlighter();
    highlighter.addClassApplier(rangy.createClassApplier('highlight', {
        onElementCreate: function (el) {
            el.setAttribute('name', id);
        },
        elementProperties: {
            className: id
        }
    }));

    function highlight(e) {
        e.preventDefault();
        highlighter.highlightSelection('highlight');

        rangy.getSelection().removeAllRanges();

        id = (S4() + S4() + "-" + S4() + "-4" + S4().substr(0, 3) + "-" + S4() + "-" + S4() + S4() + S4()).toLowerCase();
    }

    //var hls = document.getElementsByClassName("highlight");
    //if (hls) {
    //    for (var i = 0; i < hls.length; i++) {
    //        hls[i].addEventListener('click', showHlModal(hls[i]));
    //    }
    //}
    //function showHlModal(e) {
    //    console.log("clicked in hl");
    //    e = e || window.event;
    //    var target = e.target || e.srcElement;
    //    var highlight = highlighter.getHighlightForElement(target);
    //    console.log("highlight", highlight);
    //    console.log("target", target);
    //    if (target.classList.contains('highlight') || highlight) {
    //        console.log("it's a highlight");
    //        var modal = document.createElement('div');
    //        modal.className = "highlightModal";
    //        target.appendChild(modal);
    //        var targetId = target.getAttribute('name');
    //        var confirm = document.createElement('a');
    //        confirm.className = "btn btn--standard confirm jcq";
    //        confirm.innerHTML = "Clear Highlight";
    //        confirm.addEventListener('click', function (e) {
    //            modal.parentNode.removeChild(modal);
    //            if (highlight) {
    //                highlighter.removeHighlights([highlight]);
    //            }
    //            else {
    //                console.log("target Id", targetId);
    //                clearHighlights(targetId);                  
    //            }
    //        });
    //        var cancel = document.createElement('a');
    //        cancel.className = "btn btn--standard cancel jcq";
    //        cancel.innerHTML = "Cancel";
    //        cancel.addEventListener('click', function (e) {
    //            e.preventDefault();
    //            modal.parentNode.removeChild(modal);
    //        });

    //        modal.appendChild(confirm);
    //        modal.appendChild(cancel);
    //    }
    //}

    //function clearHighlights(id) {
    //    var els = document.getElementsByName(id);
    //    console.log("els", els);
    //    console.log("els length", els.length);
    //    for (var i = 0; i < els.length; i++) {
    //        var inner = els[i].innerHTML;
    //        console.log("inner", inner);
    //        els[i].outerHTML = inner;
    //        // els[i].classList.remove("highlight");
    //    }
    //    if (document.getElementsByName(id).length > 0) {
    //        clearHighlights(id);
    //    }
    //}

    if (document.getElementById('saveFileName')) { document.getElementById('saveFileName').addEventListener('click', saveNewDocument); }
    if (document.getElementById('updateDoc')) { document.getElementById('updateDoc').addEventListener('click', updateDocument); }

    function saveNewDocument() {
        console.log(window.location.pathname);
        model.SavedName = docName + " - " + document.getElementById("nameInput").value;
        model.UserId = "1234";
        model.DocumentId = parentId;
        var content = document.querySelector('.tile--content--main--content');
        var saveContent = content.innerHTML;
        console.log(saveContent);
        model.UserSavedChapters.push({
            HtmlToShow: saveContent,
            ChapterId: pageId
        });
        $.ajax({
            type: "POST",
            data: JSON.stringify(model),
            url: "/Umbraco/api/HighlightApi/SaveDocument",
            contentType: "application/json"
        }).done(function (res) {
            var newUrl = window.location.pathname + "/customchapter?docId=" + res.Id + "&chapId=" + pageId;
            window.location = newUrl;
        });
    }

    function updateDocument() {
        var content = document.querySelector('.tile--content--main--content');
        console.log("saving");
        var saveContent = content.innerHTML;
        chapMod.HtmlToShow = saveContent;
        chapMod.ChapterId = pageId;
        chapMod.Id = chapGuidId;
        chapMod.ParentId = docId;
        $.ajax({
            type: "POST",
            data: JSON.stringify(chapMod),
            url: "/Umbraco/api/HighlightApi/UpdateChapter",
            contentType: "application/json"
        }).done(function (res) {
            console.log("result", res);
            $('#confirmationMessage').fadeIn('slow', function () {
                $('#confirmationMessage').delay(5000).fadeOut();
            });
        });
    }
};

export default highlight;