var modal = document.getElementById("myModal");
var saveChanges = document.getElementById("saveChanges");
var leavePage = document.getElementById("leavePage");
var fileNameWrapper = document.getElementById("fileNameWrapper");
var changesWrapper = document.getElementById("changesWrapper");
var close = document.getElementsByClassName("close")[0];
var saveFileName = document.getElementById("saveFileName");
var closeModal = document.getElementById("closeModal");

if(saveChanges){
    saveChanges.onclick = function(e) {
      e.preventDefault();
      modal.style.display = "block";
      fileNameWrapper.style.display = "block";
      changesWrapper.style.display = "none";
  };
}
if(leavePage){
    leavePage.onclick = function(e) {
      e.preventDefault();
      modal.style.display = "block";
      changesWrapper.style.display = "block";
      fileNameWrapper.style.display = "none";
  };
}
if(modal){
  close.onclick = function(e) {
    e.preventDefault();
    modal.style.display = "none";
  };
}
if(modal){
  window.onclick = function(event) {
    if (event.target == modal) {
        modal.style.display = "none";
    }
  };
}
if(saveFileName) {
    saveFileName.onclick = function(e) {
        e.preventDefault();
        var getName = document.getElementById("nameInput").value;
        if(getName == "") {
            var error = document.getElementsByClassName("error")[0];
            error.style.display = "block";
        } else {
           // alert(getName);
        }
    };
}
if(closeModal) {
    closeModal.onclick = function(e) {
        e.preventDefault();
        modal.style.display = "none";
    };
}